<template>
  <section id="home" class="home">
    <div class="background">
      <!-- Grid de 9 imagens -->
      <div class="image-grid">
        <div v-for="(image, index) in gridImages" :key="index" class="grid-image" :style="{ backgroundImage: `url(${image})` }"></div>
      </div>
      <div class="overlay"></div>
      <div class="content">
        <h1 class="phrase">{{ currentPhrase }}</h1>
        <p>
          O Vereador Marcos "Alemão do Munck" reafirma seu compromisso de trabalhar
          para transformar a cidade em um lugar melhor para se viver. Com foco em
          ações que valorizam a família, promovem o desenvolvimento econômico e
          melhoram a infraestrutura urbana, seu mandato será pautado pela ética,
          transparência e pelo desejo de servir à comunidade.
        </p>
        <button @click="handleClick">Conheça nosso trabalho</button>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  data() {
    return {
      currentPhrase: '',
      phrases: [
        "Compromisso com a infraestrutura urbana e a qualidade de vida para todos!",
        "Mais parques, mais lazer, mais bem-estar para nossas famílias.",
        "Defendendo os valores da família tradicional e protegendo nossos laços familiares.",
        "Por uma cidade mais segura, com vias bem sinalizadas e manutenção de qualidade.",
        "Vereador Marcos Alemão do Munck: transformando ações em resultados para a comunidade."
      ],
      images: [
        require('@/assets/image01.jpeg'),
        require('@/assets/image02.jpeg'),
        require('@/assets/image03.jpeg'),
        require('@/assets/image04.jpeg'),
        require('@/assets/image05.jpeg'),
        require('@/assets/image06.jpeg'),
        require('@/assets/image07.jpeg'),
        require('@/assets/image08.jpeg'),
        require('@/assets/image09.jpeg'),
        require('@/assets/image10.jpeg'),
        require('@/assets/image11.jpeg'),
        require('@/assets/perfil1.jpg'),
        require('@/assets/perfil2.jpg'),
      ],
      gridImages: [],
      imageIntervals: [], // Array para armazenar intervalos individuais
    };
  },
  mounted() {
    this.initializeGrid();
    this.setCurrentPhrase(); // Define a frase inicial

    // Inicializa intervalos para cada imagem do grid
    this.imageIntervals = this.gridImages.map(() => this.getRandomTime());

    // Atualiza imagens em seus intervalos individuais
    this.gridImages.forEach((_, index) => {
      setInterval(() => {
        this.updateImage(index);
      }, this.imageIntervals[index]);
    });

    // Atualiza a frase a cada 10 segundos
    setInterval(() => {
      this.updatePhrase();
    }, 10000); // 10 segundos

    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll(); // Verificar a posição inicial
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    // Inicializa o grid com 9 imagens aleatórias
    initializeGrid() {
      this.gridImages = Array(9)
        .fill()
        .map(() => this.getRandomImage());
    },
    // Retorna uma imagem aleatória da lista
    getRandomImage() {
      const randomIndex = Math.floor(Math.random() * this.images.length);
      return this.images[randomIndex];
    },
    // Atualiza a imagem no índice especificado
    updateImage(index) {
      // Atualiza a imagem diretamente no array
      this.gridImages[index] = this.getRandomImage();
      // Atualiza o intervalo para a próxima troca de imagem
      this.imageIntervals[index] = this.getRandomTime();
      setTimeout(() => {
        this.updateImage(index);
      }, this.imageIntervals[index]);
    },
    // Retorna um intervalo de tempo aleatório entre 5 e 25 segundos
    getRandomTime() {
      return Math.floor(Math.random() * (45000 - 5000 + 1)) + 10000;
    },
    handleScroll() {
      const homeSection = document.getElementById('home');
      const rect = homeSection.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        homeSection.classList.add('scrolled');
      } else {
        homeSection.classList.remove('scrolled');
      }
    },
    handleClick() {
      window.open('https://www.instagram.com/marcos_alemaodomunck/', '_blank');
    },
    setCurrentPhrase() {
      // Define uma frase aleatória ao carregar
      const randomIndex = Math.floor(Math.random() * this.phrases.length);
      this.currentPhrase = this.phrases[randomIndex];
    },
    updatePhrase() {
      // Define uma nova frase aleatória
      const randomIndex = Math.floor(Math.random() * this.phrases.length);
      this.currentPhrase = this.phrases[randomIndex];
    }
  }
};
</script>

<style scoped>
.home {
  color: white;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.background {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-attachment: fixed; /* Efeito Parallax */
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.grid-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out;
}


.grid-item {
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 68, 102, 0.4); /* Azul escuro */
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.4); /* Fundo semi-transparente */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 800px;
  margin: 0 auto;
  backdrop-filter: blur(10px);
}

.phrase {
  font-size: 2rem;
  margin: 0;
  color: #ff6600; /* Laranja para a frase */
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1s ease, transform 1s ease;
}

.home.scrolled .phrase {
  opacity: 1;
  transform: translateY(0);
}

p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

button {
  background-color: #0066cc; /* Azul */
  border: none;
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button:hover {
  background-color: #ff6600; /* Laranja */
  transform: scale(1.05); /* Efeito de zoom ao passar o mouse */
}

@media (max-width: 768px) {
  .phrase {
    font-size: 2rem;
  }
  p {
    font-size: 1rem;
  }

  .image-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }
}
</style>
