<template>
  <div id="app">
    <NavbarSection />
    <HomeSection />
    <QuemSouEuSection />
    <ProjetosSection />
    <GaleriaSection />
    <ContatoSection />
  </div>
</template>

<script>
import NavbarSection from './components/NavbarSection.vue';
import HomeSection from './components/HomeSection.vue';
import QuemSouEuSection from './components/QuemSouEuSection.vue';
import ProjetosSection from './components/ProjetosSection.vue';
import GaleriaSection from './components/GaleriaSection.vue';
import ContatoSection from './components/ContatoSection.vue';

export default {
  components: {
    NavbarSection,
    HomeSection,
    QuemSouEuSection,
    ProjetosSection,
    GaleriaSection,
    ContatoSection
  }
};
</script>

<style>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
/* Estilo da barra de rolagem para WebKit */
::-webkit-scrollbar {
    width: 8px; /* Largura da barra de rolagem */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ff6600; /* Cor laranja para a "thumb" */
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1); /* Cor de fundo da trilha */
  }
</style>