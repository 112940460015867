<template>
  <section id="projetos" class="projetos">
    <h2>Projetos e Propostas</h2>
    <div class="card-container">
      <div class="card" v-for="(projeto, index) in projetos" :key="index">
        <div class="card-content">
          <h3>{{ projeto.name }}</h3>
          <p>{{ projeto.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      projetos: [
        {
          name: "Infraestrutura Urbana",
          description: "Melhoria contínua da infraestrutura urbana, com foco em vias públicas, calçadas e iluminação, além de obras de pavimentação, drenagem e saneamento básico.",
          image: "perfil1.jpg"
        },
        {
          name: "Áreas de Lazer e Parques Municipais",
          description: "Criação e revitalização de espaços públicos para lazer e esporte, como parques municipais e praças, com instalação de equipamentos esportivos e paisagismo.",
          image: "perfil1.jpg"
        },
        {
          name: "Mobilidade Urbana",
          description: "Incentivo à mobilidade ativa, construção de ciclovias, calçadas acessíveis e melhorias no transporte público, com renovação da frota e novas linhas.",
          image: "perfil1.jpg"
        },
        {
          name: "Proteção à Família Tradicional",
          description: "Políticas de apoio e valorização da família tradicional, assistência social e combate à violência doméstica, promovendo eventos comunitários e programas educativos.",
          image: "perfil1.jpg"
        },
        {
          name: "Empreendedorismo e Desenvolvimento Econômico",
          description: "Incentivo ao empreendedorismo local, regulamentação de leis, acesso a crédito, capacitação e criação de feiras para promover o comércio local.",
          image: "perfil1.jpg"
        },
        {
          name: "Articulação Política e Captação de Recursos",
          description: "Parcerias com governos e setor privado para captar recursos para projetos de infraestrutura, saúde, educação e segurança.",
          image: "perfil1.jpg"
        }
      ]
    };
  }
};
</script>

<style scoped>
.projetos {
  padding: 2rem;
  background-color: #f9f9f9;
}

h2 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #004466;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.card {
  flex: 1 1 calc(33% - 1.5rem); /* Ajuste para 3 cartões por linha */
  border: 2px solid #0066cc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #fff;
  max-width: 350px; /* Aumente o tamanho máximo para melhor visualização */
}

.card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

img {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Ajuste a imagem para cobrir uniformemente */
}

.card-content {
  padding: 1rem;
  background-color: #e6f7e6;
}

h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #ff6600;
}

p {
  font-size: 1rem;
  color: #004466;
}

/* Media Query para mobile */
@media (max-width: 768px) {
  .card {
    flex: 1 1 100%;
    margin-bottom: 1.5rem;
  }
}
</style>