<template>
  <footer id="contato" class="contato">
    <h2>Contato</h2>
    <p>Email: Marcosadiellima@hotmail.com</p>
    <p>Telefone: (47) 9708-2370</p>
    <div class="social-media">
      <a href="https://www.instagram.com/marcos_alemaodomunck/" target="_blank" rel="noopener noreferrer">Instagram</a>
    </div>
  </footer>
</template>

<style scoped>
.contato {
  background-color: #004080; /* Fundo azul escuro */
  color: white;
  padding: 2rem;
  text-align: center;
  border-top: 4px solid #ff6600; /* Borda laranja no topo */
}

.contato h2 {
  color: #ff6600; /* Cor laranja para o título */
  margin-bottom: 1rem;
}

.contato p {
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.social-media {
  margin-top: 1.5rem;
}

.social-media a {
  color: #00cc66; /* Verde para os links */
  margin: 0 1rem;
  text-decoration: none;
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-media a:hover {
  color: #ff6600; /* Laranja no hover */
}
</style>
