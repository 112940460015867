<template>
  <section id="quem-sou-eu" class="quem-sou-eu">
    <h2>Quem sou eu</h2>
    <div class="container">
      <div class="photo">
        <img src="../assets/home1.png" alt="Foto do candidato">
      </div>
      <div class="bio">
        <p>Meu nome é Marcos Adiel Lima, tenho 25 anos, sou um jovem empreendedor, cristão e conservador, natural de Joinville. Servi ao Exército Brasileiro em 2018, conquistando a patente de Aspirante a Oficial, onde despertou um forte sentimento de amor pela nossa pátria.</p>
        <p>Desde cedo, aprendi o valor do trabalho árduo e tenho me destacado como empreendedor, buscando conhecimento e excelência em meus serviços, tornando-me conhecido como Alemão do Munck.</p>
        <p>Realizei trabalhos solidários em missões humanitárias no Rio Grande do Sul, usando meu caminhão para transportar e distribuir mais de 15 toneladas de doações.</p>
        <p>Essas experiências geram um desejo profundo de contribuir mais para a comunidade. Insatisfeito com a política atual e indignado com tantas injustiças, decidi me tornar candidato a Vereador. Não quero ser só mais um político qualquer, mas sim defender e lutar pelos pensamentos conservadores, pela família, pelos empreendedores, trabalhar pela desburocratização e, como resultado, gerar frutos de liberdade a todos!</p>
      </div>
    </div>
  </section>
</template>

<style scoped>
.quem-sou-eu {
  background-color: #e6f7f7; /* Fundo verde claro */
  padding: 4rem 2rem;
  text-align: center; /* Centralizar o h2 */
}

.quem-sou-eu h2 {
  font-size: 2rem;
  color: #ff6600; /* Laranja para o título */
  margin-bottom: 2rem; /* Espaço abaixo do h2 */
}

.container {
  display: flex;
  flex-direction: column; /* Imagem acima do texto no mobile */
  align-items: center;
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.photo img {
  width: 100%;
  max-width: 400px;
  border-radius: 12px;
  object-fit: cover;
  border: 2px solid #004080; /* Borda azul escuro */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.bio {
  max-width: 800px;
  text-align: justify;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #004080; /* Azul escuro para o texto */
}

.bio p {
  margin-bottom: 0.75rem; /* Espaçamento mais sutil entre parágrafos */
}

@media (min-width: 768px) {
  .container {
    flex-direction: row; /* Imagem ao lado do texto em telas maiores */
    align-items: flex-start;
    gap: 3rem; /* Espaçamento entre imagem e biografia */
  }

  .bio {
    text-align: left;
  }
}
</style>
