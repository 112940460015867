<template>
  <nav :class="{ scrolled: isScrolled }">
    <button class="menu-toggle" @click="toggleMenu">
      <span class="menu-bar"></span>
      <span class="menu-bar"></span>
      <span class="menu-bar"></span>
    </button>
    <ul :class="{ 'menu-active': isMenuActive }">
      <li><a href="#home" @click.prevent="scrollToSection('home')">Home</a></li>
      <li><a href="#quem-sou-eu" @click.prevent="scrollToSection('quem-sou-eu')">Quem sou eu</a></li>
      <li><a href="#projetos" @click.prevent="scrollToSection('projetos')">Projetos</a></li>
      <li><a href="#galeria" @click.prevent="scrollToSection('galeria')">Galeria</a></li>
      <li><a href="#contato" @click.prevent="scrollToSection('contato')">Contato</a></li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isScrolled: false,
      isMenuActive: false,
    };
  },
  methods: {
    scrollToSection(sectionId) {
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
    toggleMenu() {
      this.isMenuActive = !this.isMenuActive;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
nav {
  position: fixed;
  top: 0;
  right: 0;
  width: 50%; /* Ajuste para 100% da largura */
  height: 40px;
  background-color: rgba(0, 68, 102, 0.6); /* Azul escuro com transparência */
  clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%); /* Lado esquerdo diagonal */
  display: flex;
  align-items: center;
  justify-content: center; /* Centralizar itens horizontalmente */
  padding: 1rem 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  z-index: 1000;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

nav.scrolled {
  background-color: rgba(0, 68, 102, 0.8); /* Azul sólido ao rolar */
}

ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
}

li {
  position: relative;
}

a {
  color: #f4f4f4;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

a:hover {
  background-color: #ff6600; /* Laranja no hover */
  color: #fff;
}

a.active {
  background-color: #0066cc; /* Azul quando ativo */
  color: #fff;
}

.menu-toggle {
  display: none;
  flex-direction: column;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.menu-bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 4px 0;
  transition: 0.3s;
}

/* Media Query para mobile */
@media (max-width: 768px) {
  nav {
    width: 100%; /* Ocupa 100% da largura em telas pequenas */
    height: auto; /* Ajuste a altura conforme o conteúdo */
    clip-path: none; /* Remove a diagonal em telas pequenas */
    padding: 1rem;
    flex-direction: column; /* Exibe os itens em coluna */
  }
  
  .menu-toggle {
    display: flex; /* Mostra o botão de menu */
  }
  
  ul {
    display: none; /* Esconde o menu por padrão */
    flex-direction: column; /* Coloca os itens em coluna */
    align-items: center; /* Centraliza os itens horizontalmente */
    gap: 0.5rem; /* Espaçamento entre os itens */
    width: 100%; /* Ocupa toda a largura */
    background-color: rgba(0, 68, 102, 0.9); /* Fundo do menu */
    position: absolute;
    top: 40px; /* Ajusta a posição abaixo do botão de menu */
    left: 0;
    z-index: 1001;
  }

  ul.menu-active {
    display: flex; /* Mostra o menu quando ativo */
  }

  a {
    padding: 0.5rem;
    font-size: 1rem;
  }

  .menu-bar:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
  }

  .menu-bar:nth-child(2) {
    opacity: 0;
  }

  .menu-bar:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
  }
}
</style>
